<template>
	<div class="pui-form">
		<visit-modals :modelName="modelName"></visit-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<visit-form-header :modelPk="modelPk"></visit-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<VisitTemplateForm
				v-model="tabmodel"
				:model="model"
				:formDisabled="formDisabled"
				:modelLoaded="modelLoaded"
				:isCreatingElement="isCreatingElement"
				:modelName="modelName"
				:pk="pk"
				:isModalDialog="isModalDialog"
				:showDocuments="showDocuments"
			></VisitTemplateForm>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="customSave"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import visitActions from './VisitActions';
import VisitFormHeader from './VisitFormHeader.vue';
import VisitTemplateForm from './VisitTemplateForm.vue';
import visitModals from './VisitModals.vue';

export default {
	name: 'visit-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		VisitFormHeader,
		VisitTemplateForm,
		'visit-modals': visitModals
	},
	data() {
		return {
			modelName: 'visit',
			actions: visitActions.formactions,
			tabmodel: 'maintab'
		};
	},
	methods: {
		afterGetData() {
			if (this.model.statusid == 'IN') {
				this.model.atd = null;
			}
		},
		customSave() {
			if (this.isCreatingElement) {
				this.save();
			} else {
				this.update(true, '/visit/update');
			}
		}
	},
	computed: {},
	created() {}
};
</script>
